import * as api from '../api';
import {showMessage} from './messages';

export const topUpWallet = topUpData => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.topUpWallet(topUpData, token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_SUCCESSFUL'));
  } else if (res.json.error === 'no_user') {
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_NO_USER'));
  } else {
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_FAILED'));
  }
};

export const reportAllPlayers = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.reportAllPlayers(token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const reportTransactions = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.reportTransactions(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const playersReceived = players => ({
  type: 'ADMIN_PLAYERS_LIST_RECEIVED',
  players
});

export const searchPlayers = surname => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayers(surname, token);
  if (res.status === 200) {
    dispatch(playersReceived(res.json));
  } else {
    dispatch(showMessage('SEARCH', `Error ${res.status}: ${res.json.error}`));
  }
};

export const storagePricesReceived = prices => ({
  type: 'ADMIN_STORAGE_PRICES_RECEIVED',
  prices
});

export const getStoragePrices = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getStoragePrices(token);
  if (res.status === 200) {
    dispatch(storagePricesReceived(res.json));
  } else {
    dispatch(showMessage('STORAGE PRICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const changeStoragePrices = values => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.changeStoragePrices(values, token);
  if (res.status === 200) {
    dispatch(getStoragePrices());
    dispatch(showMessage('Cambia prezzi', 'PRICES_CHANGE_SUCCESS'));
  } else {
      if(res.json.error === "all_prices_must_be_at_least_0")
        dispatch(showMessage('Cambia prezzi', "Tutti i prezzi inseriti devono essere maggiori o uguali a 0"));
      else dispatch(showMessage('Cambia prezzi', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showChangePasswordModal = () => ({
  type: 'ADMIN_SHOW_CHANGE_PASSWORD_MODAL'
});

export const hideChangePasswordModal = () => ({
  type: 'ADMIN_HIDE_CHANGE_PASSWORD_MODAL'
});

export const selectPlayer = selectedPlayer => ({
  type: 'ADMIN_PLAYER_SELECTED',
  selectedPlayer
});

export const changePassword = credential => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.changePassword(credential, token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('Cambia password', 'CHANGE_PASSWORD_SUCCESSFUL'));
  } else if (res.json.error === 'password_not_matched') {
    dispatch(showMessage('Cambia password', 'PASSWORD_NO_MATCHED'));
  } else {
    dispatch(showMessage('Cambia password', `Error ${res.status}: ${res.json.error}`));
  }
};

export const createPartner = partner => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.createPartner(partner, token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('CREATE_PARTNER', 'CREATE_PARTNER_SUCCESSFUL'));
  } else if (res.json.error === 'password_not_matched') {
    dispatch(showMessage('CREATE_PARTNER', 'PASSWORD_NO_MATCHED'));
  } else if (res.json.error === 'invalid_IVA') {
    dispatch(showMessage('CREATE_PARTNER', 'Partita IVA non valida.'));
  } else if (res.json.error === 'email_already_registered') {
    dispatch(showMessage('CREATE_PARTNER', 'EMAIL_ALREADY_REGISTERED'));
  } else if (res.json.error === 'invalid_email') {
    dispatch(showMessage('CREATE_PARTNER', 'INVALID_EMAIL'));
  } else {
    dispatch(showMessage('CREATE_PARTNER', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnersReceived = partners => ({
  type: 'ADMIN_PARTNERS_LIST_RECEIVED',
  partners
});

export const searchPartners = (businessName = "all") => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartners(token, businessName);
  if (res.status === 200) {
    dispatch(partnersReceived(res.json));
  } else {
    dispatch(showMessage('SEARCH', `Error ${res.status}: ${res.json.error}`));
  }
};

export const slotReceived = slot => ({
  type: 'ADMIN_SLOT_RECEIVED',
  slot
});

export const searchSlot = slotId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getSlot(token, slotId);
  if (res.status === 200) {
    dispatch(slotReceived(res.json));
  } else if(res.json.error === "no_slot"){
    dispatch(showMessage('SEARCH', 'NO_SLOT'));
  } else {
    dispatch(showMessage('SEARCH', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deleteSlot = slotId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteSlot(token, slotId);

  if (res.status === 200) {
    dispatch(slotReceived(null))
    dispatch(showMessage('SLOT', 'SLOT_DELETE_SUCCESSFUL'));
  } else if(res.json.error === "slot_not_started"){
    dispatch(showMessage('SLOT', 'SLOT_NOT_STARTED'));
  } else {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const selectPartner = selectedPartner => ({
  type: 'ADMIN_PARTNER_SELECTED',
  selectedPartner
});

export const partnerFieldsReceived = partnerFields => ({
  type: 'ADMIN_PARTNER_FIELDS_RECEIVED',
  partnerFields
});

export const partnerServicesReceived = partnerServices => ({
  type: 'ADMIN_PARTNER_SERVICES_RECEIVED',
  partnerServices
});

export const getPartnerFields = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerFields(partnerId, token);
  if (res.status === 200) {
    dispatch(partnerFieldsReceived(res.json));
  } else {
    dispatch(showMessage('FIELDS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const getPartnerServices = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerServices(partnerId, token);
  if (res.status === 200) {
    dispatch(partnerServicesReceived(res.json));
  } else {
    dispatch(showMessage('SERVICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const updatePartnerByAdmin = partner => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.updatePartnerByAdmin(partner, token);
  if (res.status === 200) {
    dispatch(partnersReceived([]));
    dispatch(showMessage('UPDATE_PARTNER', 'PARTNER_UPDATED'));
  } else {
    dispatch(showMessage('UPDATE_PARTNER', res.json.error.toUpperCase()));
  }
};

export const updatePartnerVideoCostByAdmin = partner => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.updatePartnerVideoCostByAdmin(partner, token);
  if (res.status === 200) {
    dispatch(partnersReceived([]));
    dispatch(showMessage('UPDATE_PARTNER', 'PARTNER_UPDATED'));
  } else {
    dispatch(showMessage('UPDATE_PARTNER', res.json.error.toUpperCase()));
  }
};

export const updatePartnerFieldByAdmin = field => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.updatePartnerFieldByAdmin(field, token);
  if (res.status === 200) {
    dispatch(partnersReceived([]));
    dispatch(showMessage('UPDATE_PARTNER', 'FIELD_UPDATED'));
  } else {
    dispatch(showMessage('UPDATE_PARTNER', `Error ${res.status}: ${res.json.error}`));
  }
};

export const updatePartnerServiceByAdmin = service => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.updatePartnerServiceByAdmin(service, token);
  if (res.status === 200) {
    dispatch(partnersReceived([]));
    dispatch(showMessage('UPDATE_PARTNER', 'SERVICE_UPDATED'));
  } else {
    dispatch(showMessage('UPDATE_PARTNER', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showSponsorModal = () => ({
  type: 'ADMIN_SHOW_SPONSOR_MODAL'
});

export const hideSponsorModal = () => ({
  type: 'ADMIN_HIDE_SPONSOR_MODAL'
});

export const sponsorsReceived = sponsors => ({
  type: 'ADMIN_SPONSORS_RECEIVED',
  sponsors
});

export const getSponsors = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getSponsors(token);
  if (res.status === 200) {
    dispatch(sponsorsReceived(res.json));
  } else {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const saveSponsor = sponsor => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  if (sponsor.id) {
    res = await api.updateSponsor(sponsor, token);
  } else {
    res = await api.saveSponsor(sponsor, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('SPONSOR', 'SPONSOR_SAVED'));
  } else {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getSponsors());
  dispatch(hideSponsorModal());
};

export const newSponsor = () => ({
  type: 'ADMIN_NEW_SPONSOR'
});

export const sponsorSelected = selectedSponsor => ({
  type: 'ADMIN_SPONSOR_SELECTED',
  selectedSponsor
});

export const deleteSponsorImageFile = (file, sponsorId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteSponsorImageFile(token, file, sponsorId);

  if (res.status === 200) {
    dispatch(refreshSelectedSponsor(sponsorId));
  } else {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const refreshSelectedSponsor = sponsorId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getSponsor(token, sponsorId);
  if (res.status === 200) {
    dispatch(sponsorSelected(res.json));
    dispatch(getSponsors());
  } else {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deleteSponsor = sponsorId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteSponsor(token, sponsorId);

  if (res.status === 200) {
    dispatch(hideSponsorModal());
    dispatch(getSponsors());
  } else {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnerRtvChannelsReceived = partnerRtvChannels => ({
  type: 'ADMIN_PARTNER_RTV_CHANNELS_RECEIVED',
  partnerRtvChannels
});

export const getPartnerRtvChannels = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerRtvChannels(partnerId, token);
  if (res.status === 200) {
    dispatch(partnerRtvChannelsReceived(res.json));
  } else {
    dispatch(showMessage('RTV_CHANNELS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const saveRtvChannels = rtvChannels => async (dispatch, getState) => {
  const {login: {token}, admin: {selectedPartner}} = getState();
  
  const res = await rtvChannels.map(async c => {
    if (c.id) {
      return await api.updatePartnerRtvChannel(c, token)
    } else {
      return await api.createPartnerRtvChannel(c, token);
    }    
  });  

  Promise.all(res).then(values => {
    const error = values.find(v => v.status !== 200 && v.status !== 201);

    if (error) {
      dispatch(showMessage('RTV_CHANNELS', `Error ${error.status}: ${error.json.error}`));
    } else {
      dispatch(showMessage('RTV_CHANNELS', 'RTV_CHANNELS_SAVED'));      
    }
  
    dispatch(getPartnerRtvChannels(selectedPartner.uid));
  });
  
};

export const deleteRtvChannel = rtvChannelId => async (dispatch, getState) => {
  const {login: {token}, admin: {selectedPartner}} = getState();
  const res = await api.deleteRtvChannel(rtvChannelId, token);

  if (res.status === 200) {
    dispatch(getPartnerRtvChannels(selectedPartner.uid));
  } else {
    dispatch(showMessage('RTV_CHANNELS', res.json.error.toUpperCase()));
  }
};

export const newXml = (fieldId) => ({
  type: 'ADMIN_NEW_XML',
  fieldId: parseInt(fieldId, 10)
});

export const newXmlPartnerVideo = partnerId => ({
  type: 'ADMIN_NEW_XML_PARTNER_VIDEO',
  partnerId: parseInt(partnerId, 10)
});

export const showXmlModal = () => ({
  type: 'ADMIN_SHOW_XML_MODAL'
});

export const hideXmlModal = () => ({
  type: 'ADMIN_HIDE_XML_MODAL'
});

export const showXmlPartnerVideoModal = () => ({
  type: 'ADMIN_SHOW_XML_PARTNER_VIDEO_MODAL'
});

export const hideXmlPartnerVideoModal = () => ({
  type: 'ADMIN_HIDE_XML_PARTNER_VIDEO_MODAL'
});

export const saveXml = xml => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  res = await api.saveXml(xml, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('XML', 'FILE_SAVED'));
  } else {
    dispatch(showMessage('XML', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(hideXmlModal());
};

export const savePartnerVideoXml = xml => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  res = await api.savePartnerVideoXml(xml, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('XML', 'FILE_SAVED'));
  } else {
    dispatch(showMessage('XML', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(hideXmlModal());
};

export const showCameraModal = () => ({
  type: 'ADMIN_SHOW_CAMERA_MODAL'
});

export const hideCameraModal = () => ({
  type: 'ADMIN_HIDE_CAMERA_MODAL'
});

export const newCamera = (fieldId) => ({
  type: 'ADMIN_NEW_CAMERA',
  fieldId: fieldId,
});

export const editCamera = (camera) => ({
  type: 'ADMIN_EDIT_CAMERA',
  camera: camera,
});

export const createCamera = (values, partnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.createCamera(values, token);
  if (res.status !== 201) {
    dispatch(showMessage('CAMERA', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(getPartnerFields(partnerId));
    dispatch(hideCameraModal());
  }
};

export const modifyCamera = (values, partnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.modifyCamera(values, token);
  if (res.status !== 200) {
    dispatch(showMessage('CAMERA', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(getPartnerFields(partnerId));
    dispatch(hideCameraModal());
  }
};

export const deleteCamera = (cameraId, partnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteCamera(cameraId, token);
  if (res.status !== 200) {
    dispatch(showMessage('CAMERA', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(getPartnerFields(partnerId));
    dispatch(hideCameraModal());
  }
};

export const reportSubscriptions = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.adminReportSubscriptions(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const changePartnerPermissions = (values, partnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  res = await api.changePartnerPermissions(values, partnerId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('VIDEO_STORE', 'VIDEO_STORE_VISIBILITY_CHANGE'));
  } else {
    dispatch(showMessage('VIDEO_STORE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const reportUsage = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.adminReportUsage(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const showCategoryModal = () => ({
  type: 'ADMIN_SHOW_CATEGORY_MODAL'
});

export const hideCategoryModal = () => ({
  type: 'ADMIN_HIDE_CATEGORY_MODAL'
});

export const createCategory = values => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.adminCreateCategory(values, token);
  if (res.status !== 201) {
    dispatch(showMessage('CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(hideCategoryModal());
    dispatch(getRtvCategories());
  }
};

export const rtvCategoriesReceived = categories => ({
  type: 'ADMIN_RTV_CATEGORIES_RECEIVED',
  categories
});

export const getRtvCategories = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getRtvCategories(token);
  if (res.status === 200) {
    dispatch(rtvCategoriesReceived(res.json));
  } else {
    dispatch(showMessage('RTV_CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const selectCategory = category => ({
  type: 'ADMIN_SELECT_RTV_CATEGORY',
  category
});

export const editCategory = values => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.adminEditCategory(values, token);
  if (res.status !== 200) {
    dispatch(showMessage('CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(hideCategoryModal());
    dispatch(getRtvCategories());
  }
};

export const addOrRemoveChannelFromCategory = (rtvChannelId, add, categoryId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.adminAddOrRemoveChannelFromCategory(rtvChannelId, add, categoryId, token);
  if (res.status !== 200) {
    dispatch(showMessage('CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(getRtvCategories());
  }
};

export const deleteCategory = id => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.adminDeleteCategory(id, token);
  if (res.status !== 200) {
    dispatch(showMessage('CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
  else{
    dispatch(hideCategoryModal());
    dispatch(selectCategory({}));
    dispatch(getRtvCategories());
    dispatch(searchPartners());
  }
};

export const deleteCategoryImageFile = (file, categoryId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteCategoryImageFile(token, file, categoryId);

  if (res.status !== 200) {
    dispatch(showMessage('SPONSOR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const downloadNvrTracking = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.downloadNvrTracking(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};
